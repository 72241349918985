import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { map, catchError } from 'rxjs/operators';  
import { Subscription, of } from 'rxjs';

import { PrescriptionService } from '../../service/prescription.service';
import { Prescription } from '../../shared/models/prescription.model';
import { GlobalConstants } from 'src/app/shared/global-constants';

import { PrescriptionStatuses, PrescriptionType } from '../../shared/pipes/prescription-status.pipe'

@Component({
  selector: 'app-prescription-status',
  templateUrl: './prescription-status.component.html',
  styleUrls: ['./prescription-status.component.css']
})
export class PrescriptionStatusComponent implements OnInit {

  sub: Subscription;
  prescription: Prescription;
  prescriptionStatusEnum = PrescriptionStatuses;
  PrescriptionTypeEnum = PrescriptionType;
  constructor(private route: ActivatedRoute,
              private prescriptionService: PrescriptionService,
              private renderer: Renderer2,
              private _snackBar: MatSnackBar,
              private router: Router) { }

  

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      const id = params.id;
      if (id) {
        this.refreshPrescription(id);
      }
    });
  }

  refreshPrescription(id:string)
  {
    this.prescriptionService.get(id).subscribe((prescription: any) => {
      if (prescription) {
        this.prescription = prescription;
        
        console.log(`pharmacy with id '${id}' found` + prescription);
        //this.giphyService.get(car.name).subscribe(url => car.giphyUrl = url);
      } else {
        console.log(`pharmacy with id '${id}' not found, returning to list`);
       
      }
    });
  }

  /*deliverPrescription(currentPrescriptionID:string):void {
    this.prescriptionService.delivered(currentPrescriptionID).subscribe(result => {
      this._snackBar.open("Delivered", "OK", {
        duration: 2000,
      }); 
      this.refreshPrescription(this.prescription.id);
     
    }, error => {
      console.error(error);
   
   });
  }*/

  deliveryFailed(currentPrescriptionID:string):void {
    this.prescriptionService.deliveryFailed(currentPrescriptionID).subscribe(result => {
      this._snackBar.open("Delivery Failed", "OK", {
        duration: 2000,
      }); 
      this.refreshPrescription(this.prescription.id);
     
    }, error => {
      console.error(error);
   
   });
  }

  pickUpSuccess(currentPrescriptionID:string):void {
    this.prescriptionService.PickedUp(currentPrescriptionID).subscribe(result => {
      this._snackBar.open("Picked up", "OK", {
        duration: 2000,
      }); 
      this.refreshPrescription(this.prescription.id);
     
    }, error => {
      console.error(error);
   
   });
  }

  pickUpFailed(currentPrescriptionID:string):void {
    this.prescriptionService.pickedupFailed(currentPrescriptionID).subscribe(result => {
      this._snackBar.open("Pickup failed", "OK", {
        duration: 2000,
      }); 
      this.refreshPrescription(this.prescription.id);
     
    }, error => {
      console.error(error);
   
   });
  }
  

}
