import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { map, catchError } from 'rxjs/operators';
import { Subscription, of } from 'rxjs';

import { PrescriptionService } from '../../service/prescription.service';
import { Prescription } from '../../shared/models/prescription.model';
import { GlobalConstants } from 'src/app/shared/global-constants';

import { PrescriptionStatuses, PrescriptionType } from '../../shared/pipes/prescription-status.pipe'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from 'src/app/shared/view/confirmation-dialog/confirmation-dialog.component';
import { Pharmacy } from 'src/app/shared/models/pharmacy.model';

declare var $: any;
@Component({
  selector: 'app-prescription-detail',
  templateUrl: './prescription-detail.component.html',
  styleUrls: ['./prescription-detail.component.css']
})
export class PrescriptionDetailComponent implements OnInit {
  sub: Subscription;
  prescription: Prescription;
  prescriptionStatusEnum = PrescriptionStatuses;
  PrescriptionTypeEnum = PrescriptionType;
  displayCarousal = false;
  noteForm: FormGroup;
  constructor(private route: ActivatedRoute, private dialog: MatDialog,
    private prescriptionService: PrescriptionService,
    private renderer: Renderer2,
    private _snackBar: MatSnackBar,
    private router: Router, private fb: FormBuilder) { }

  psType = "";

  ngOnInit() {
    this.noteForm = this.fb.group({
      note: ['', [Validators.required, Validators.minLength(3)]],
      userID: ['']
    });
    this.sub = this.route.params.subscribe(params => {
      const id = params.id;
      this.psType = params.psType;
      if (id) {
        this.refreshPrescription(id);
      }
    });
  }

  order =null;
  refreshPrescription(id: string) {
    $('.csLoader').css({ display: 'flex' });
    this.prescriptionService.get(id, this.psType == "archived").subscribe((prescription: any) => {
    
      if (prescription) {
        console.log(prescription);
        this.prescription = prescription;


        this.noteForm = this.fb.group({
          note: ['', [Validators.required, Validators.minLength(3)]],
          userID: GlobalConstants.getcurrentUser().id
        });
        console.log(`pharmacy with id '${id}' found` + prescription);
        this.prescription.pharmacy = (JSON.parse(localStorage.getItem("pharmacieList")) as Pharmacy[]).find(x => x.id == this.prescription.pharmacyID);
        if(this.prescription.orderId){
          this.prescriptionService.getOrder(this.prescription.orderId).subscribe(or=>{
            console.log(or);
            this.order = or;
          });
        }
        //this.giphyService.get(car.name).subscribe(url => car.giphyUrl = url);
      } else {
        console.log(`pharmacy with id '${id}' not found, returning to list`);

      }
      $('.csLoader').css({ display: 'none' });
    });
  }

  cancelPrescription(currentPrescriptionID: string): void {
    this.prescriptionService.cancel(currentPrescriptionID).subscribe(result => {
      this._snackBar.open("Order Cancelled", "OK", {
        duration: 2000,
      });
      this.refreshPrescription(this.prescription.id);

    }, error => {
      console.error(error);

    });
  }

  acceptPrescription(currentPrescriptionID: string): void {
    this.prescriptionService.accept(currentPrescriptionID).subscribe(result => {
      this._snackBar.open("Order Accepted", "OK", {
        duration: 2000,
      });
      this.refreshPrescription(this.prescription.id);

    }, error => {
      console.error(error);

    });
  }

  displayImagePopup() {
    this.displayCarousal = true;
  }

  /* deliverPrescription(currentPrescriptionID: string): void {
     this.prescriptionService.delivered(currentPrescriptionID).subscribe(result => {
       this._snackBar.open("Delivered", "OK", {
         duration: 2000,
       });
       this.refreshPrescription(this.prescription.id);
 
     }, error => {
       console.error(error);
 
     });
   }*/

  readyToPickup(currentPrescriptionID: string): void {
    this.prescriptionService.readyToPickUp(currentPrescriptionID).subscribe(result => {
      this._snackBar.open("Order marked for ready to pick up", "OK", {
        duration: 2000,
      });
      this.refreshPrescription(this.prescription.id);

    }, error => {
      console.error(error);

    });
  }
  pickedUp(currentPrescriptionID: string): void {
    this.prescriptionService.pickedUp(currentPrescriptionID).subscribe(result => {
      this._snackBar.open("Order marked as picked up", "OK", {
        duration: 2000,
      });
      this.refreshPrescription(this.prescription.id);

    }, error => {
      console.error(error);

    });
  }

  readyToDeliver(currentPrescriptionID: string): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        isRadio: false,
        message: 'Please choose one action',
        buttonText: {
          ok: 'Track with OTP',
          cancel: 'Proceed without tracking'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {

      if (confirmed != undefined) {
        this.askForCashCollection(currentPrescriptionID, confirmed);
      }
      else {
        this._snackBar.open("Action cancelled", "OK", {
          duration: 2000,
        });
      }
    });


  }
  private askForCashCollection(currentPrescriptionID: string, trackWithOtp: boolean) {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        isRadio: false,
        isInputRequired: true,
        placeholder:"Enter amount here if required",
        message: 'Is cash collection required?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((val: boolean) => {
      if (val != undefined) {
        var cashCollectionIsRequired = false;
        var numberRegex = /^\s*[+-]?(\d+|\.\d+|\d+\.\d+|\d+\.)(e[+-]?\d+)?\s*$/
        if (typeof val === "boolean") {
          cashCollectionIsRequired = false;
        }
        else if (numberRegex.test(val)) {
          cashCollectionIsRequired = true;
        }
        else {
          this._snackBar.open("Please enter a valid amount.", "OK", {
            duration: 2000,
          });
          return;
        }
        this.prescriptionService.readyToDeliver(currentPrescriptionID, trackWithOtp, cashCollectionIsRequired, cashCollectionIsRequired ? parseFloat(val + "") : -1).subscribe(result => {
          this._snackBar.open("Order Marked for ready to Deliver", "OK", {
            duration: 2000,
          });
          this.refreshPrescription(this.prescription.id);

        }, error => {
          console.error(error);

        });;
      }
      else {
        this._snackBar.open("Action cancelled", "OK", {
          duration: 2000,
        });
      }
    });


  }

  outForDelivery(currentPrescriptionID: string): void {


    this.prescriptionService.outForDelivery(currentPrescriptionID).subscribe(result => {
      this._snackBar.open("Order Marked for out for Deliver", "OK", {
        duration: 2000,
      });
      this.refreshPrescription(this.prescription.id);

    }, error => {
      console.error(error);

    });


  }

  deliveryFailed(currentPrescriptionID: string): void {
    this.prescriptionService.deliveryFailed(currentPrescriptionID).subscribe(result => {
      this._snackBar.open("Delivery Failed", "OK", {
        duration: 2000,
      });
      this.refreshPrescription(this.prescription.id);

    }, error => {
      console.error(error);

    });
  }

  pickUpSuccess(currentPrescriptionID: string): void {
    this.prescriptionService.PickedUp(currentPrescriptionID).subscribe(result => {
      this._snackBar.open("Picked up succesfull", "OK", {
        duration: 2000,
      });
      this.refreshPrescription(this.prescription.id);

    }, error => {
      console.error(error);

    });
  }

  pickUpFailed(currentPrescriptionID: string): void {
    this.prescriptionService.pickedupFailed(currentPrescriptionID).subscribe(result => {
      this._snackBar.open("Pickup Failed", "OK", {
        duration: 2000,
      });
      this.refreshPrescription(this.prescription.id);

    }, error => {
      console.error(error);

    });
  }
  onNotSubmit() {
    if (this.noteForm.valid) {
      this.prescriptionService.addNote(this.prescription.id, this.noteForm.value).subscribe(x => {
        console.log(x);
        if (x) {
          this.prescription = x;
          this.noteForm.controls["note"].setValue("");
          this.noteForm.controls["note"].markAsUntouched();
        }
      });
    }
  }
}
