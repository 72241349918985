import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MatButtonModule, MatCardModule, MatChipsModule, MatInputModule, MatListModule, MatToolbarModule, MatFormFieldModule, MatSelectModule, MatProgressBarModule, MatIcon, MatIconModule, MatMenu, MatMenuModule, MatDialogModule, MatAutocomplete, MatAutocompleteModule, MatPaginator, MatPaginatorModule, MatRadioModule, MatDatepickerModule, MatNativeDateModule, MatSpinner, MatProgressSpinnerModule, MatTooltipModule  } from '@angular/material';
import {MatGridListModule} from '@angular/material/grid-list';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component'; 

import { PrescriptionComponent } from './prescription/prescription/prescription.component';
import { PrescriptionListComponent } from './prescription/prescription-list/prescription-list.component';
import { PrescriptionEditComponent } from './prescription/prescription-edit/prescription-edit.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { AppointmentComponent } from './appointment/appointment/appointment.component';
import { AppointmentListComponent } from './appointment/appointment-list/appointment-list.component';
import { AppointmentEditComponent } from './appointment/appointment-edit/appointment-edit.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { MAT_DATE_LOCALE } from '@angular/material/core'
import {NgxPrintModule} from 'ngx-print';

import { PrescriptionStatusTransformPipe, PrescriptionDeliveryTypePipe,PrescriptionStatusColorTransformPipe, PrescriptionStatuses,PrescriptionType,GenderPipe, OrderPaymentStatusPipe } from './shared/pipes/prescription-status.pipe';
import { PrescriptionDetailComponent } from './prescription/prescription-detail/prescription-detail.component';
import { DatePipe } from '@angular/common';
import { PrescriptionStatusComponent } from './prescription/prescription-status/prescription-status.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './shared/AuthGuard';
import { UserResolver } from './shared/user.resolver';
import { CreatePatientComponent } from './prescription/prescription-edit/create-patient/create-patient.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { UserRolePipe } from './shared/pipes/user-role.pipe';
import { PharmacyNamePipe } from './shared/pipes/pharmacy-name.pipe';
import { ImagePreviewListComponent } from './image-preview-list/image-preview-list.component';
import { ImageViewerModule } from 'ngx-image-viewer';
import { ReadyToPickupComponent } from './ready-to-pickup/ready-to-pickup.component';
import { LocalDateTimePipe } from './shared/pipes/local-date-time.pipe';
import { PhoneMaskDirectiveDirective } from './shared/directive/phone-mask-directive.directive';
import { InputMaskDirective } from './shared/directive/input-mask.directive';
import { TokenInterceptor } from './shared/token.httpIntercepter';
import { ConfirmationDialogComponent } from './shared/view/confirmation-dialog/confirmation-dialog.component';
import { MyPharmaciesComponent } from './ready-to-pickup/my-pharmacies/my-pharmacies.component';
import { MessagingService } from './shared/messaging.service';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { GoogleMapsModule } from '@angular/google-maps';
import { DeSanitizeMobileNumber } from './shared/pipes/DeSanitizeMobileNumber';
import { DeSanitizeOHIPNumber } from './shared/pipes/DeSanitizeOHIPNumber';
import { CreateNewConfDialogComponent } from './configuration/create-new-conf-dialog/create-new-conf-dialog.component';
import { ToDayNamePipe } from './shared/pipes/to-day-name.pipe';
import { AgendaService, DayService, MonthService, Print, PrintService, ScheduleModule, WeekService, WorkWeekService } from '@syncfusion/ej2-angular-schedule';
import { ViewAppointmetDetailsComponent } from './appointment/view-appointmet-details/view-appointmet-details.component';
import { PatientDetailComponent } from './patient-detail/patient-detail.component';
import { SelectReasonComponent } from './shared/view/select-reason/select-reason.component';
import { DeliveryRoutingComponent } from './delivery-routing/delivery-routing.component';
import { WaitingListComponent } from './appointment/waiting-list/waiting-list.component';
import { AddressAutoDirective } from './shared/directive/address-auto.directive';
import { AddEditConditionsComponent } from './configuration/add-edit-conditions/add-edit-conditions.component';
import { ConditionTypesPipe } from './shared/pipes/condition-types.pipe';
import { HomeComponent } from './home/home.component';
import { TeamsDeliveryHeaderComponent } from './home/teams-delivery-header/teams-delivery-header.component';
import { TeamsDeliveryFooterComponent } from './home/teams-delivery-footer/teams-delivery-footer.component';
import { HomeContentComponent } from './home/home-content/home-content.component';
import { QuestionaireDialogComponent } from './shared/view/questionaire-dialog/questionaire-dialog.component';
import { MinDirective } from './shared/directive/min.directive';
import { MaxDirective } from './shared/directive/max.directive';
import { OkOnlyComponent } from './shared/view/ok-only/ok-only.component';
import { EanbleTwoFAComponent } from './shared/view/eanble-two-fa/eanble-two-fa.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { LoginDialogComponent } from './shared/view/login-dialog/login-dialog.component';
import { MyOrderComponent } from './my-order/my-order.component';
import { MinorAilmentsComponent } from './minor-ailments/minor-ailments.component';
import { AilmentsDialogComponentComponent } from './ailments-dialog-component/ailments-dialog-component.component';
// import { MinorprescriptionhistoryComponent } from './minorprescriptionhistory/minorprescriptionhistory.component';
import { MapflowMinorailmentComponent } from './mapflow-minorailment/mapflow-minorailment.component';
// import { DeliveryRoutingComponentOld } from './delivery-routing/delivery-routing.component.old';

@NgModule({
  declarations: [
    AppComponent,
    PrescriptionComponent,
    PrescriptionListComponent,
    PrescriptionEditComponent,
    DashboardComponent,
    AppointmentComponent,
    AppointmentListComponent,
    AppointmentEditComponent,
    ConfigurationComponent,
    PrescriptionStatusTransformPipe,
    PrescriptionDeliveryTypePipe,
    OrderPaymentStatusPipe,
    PrescriptionStatusColorTransformPipe,
    GenderPipe,
    PrescriptionDetailComponent,
    PrescriptionStatusComponent,
    LoginComponent,
    CreatePatientComponent,
    ManageUsersComponent,
    UserRolePipe,
    PharmacyNamePipe,
    ImagePreviewListComponent,
    ReadyToPickupComponent,
    LocalDateTimePipe,
    PhoneMaskDirectiveDirective,
    InputMaskDirective,
    ConfirmationDialogComponent,
    MyPharmaciesComponent,
    DeSanitizeMobileNumber,
     DeSanitizeOHIPNumber,
      CreateNewConfDialogComponent,
       ToDayNamePipe,
        ViewAppointmetDetailsComponent, 
        PatientDetailComponent, SelectReasonComponent, DeliveryRoutingComponent, WaitingListComponent, AddressAutoDirective, AddEditConditionsComponent, ConditionTypesPipe, HomeComponent, TeamsDeliveryHeaderComponent, TeamsDeliveryFooterComponent, HomeContentComponent, QuestionaireDialogComponent, MinDirective, MaxDirective,
    DeSanitizeMobileNumber, DeSanitizeOHIPNumber, CreateNewConfDialogComponent, ToDayNamePipe, ViewAppointmetDetailsComponent, PatientDetailComponent, SelectReasonComponent, DeliveryRoutingComponent, WaitingListComponent, AddressAutoDirective, AddEditConditionsComponent, ConditionTypesPipe, HomeComponent, TeamsDeliveryHeaderComponent, TeamsDeliveryFooterComponent, HomeContentComponent, QuestionaireDialogComponent, OkOnlyComponent, EanbleTwoFAComponent, LoginPageComponent,
     LoginDialogComponent, MyOrderComponent, MinorAilmentsComponent, AilmentsDialogComponentComponent,
     MapflowMinorailmentComponent,
    // DeliveryRoutingComponentOld
  ], entryComponents: [LoginDialogComponent, CreatePatientComponent, ConfirmationDialogComponent, CreateNewConfDialogComponent, SelectReasonComponent, AddEditConditionsComponent, QuestionaireDialogComponent, OkOnlyComponent, EanbleTwoFAComponent],
  imports: [
    NgxPrintModule,
    GoogleMapsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatButtonModule,
    MatPaginatorModule,
    MatCardModule,
    MatTooltipModule,
    MatInputModule,MatAutocompleteModule,
    MatListModule,
    MatSelectModule,
    MatToolbarModule,
    MatChipsModule,
    FormsModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatCheckboxModule,MatDialogModule,MatDatepickerModule,MatNativeDateModule, 
    MatSnackBarModule,
    MatRadioModule,
    MatGridListModule,ReactiveFormsModule,MatIconModule,MatMenuModule,
    ImageViewerModule.forRoot(),
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment),
    ScheduleModule  ,

  ],
  providers: [OrderPaymentStatusPipe, MessagingService,DatePipe,PrescriptionDeliveryTypePipe,AuthGuard,UserResolver,{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },DeSanitizeMobileNumber,DeSanitizeOHIPNumber,LocalDateTimePipe,DayService, WeekService, WorkWeekService, MonthService,PrintService],
  bootstrap: [AppComponent]
})
export class AppModule { }


