import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CreatePatientComponent } from '../prescription/prescription-edit/create-patient/create-patient.component';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-patient-detail',
  templateUrl: './patient-detail.component.html',
  styleUrls: ['./patient-detail.component.css']
})
export class PatientDetailComponent implements OnInit {

  constructor(private dialog: MatDialog,private userService: UserService, private router: Router, private activatedRoute: ActivatedRoute) {

  }
  patient = null;
  oldData = null;
  ngOnInit() {
    var id = this.activatedRoute.snapshot.paramMap.get("id");

    this.userService.getPatientById(id).subscribe(x => {
      console.log(x);
      this.patient = x;
      if (x.parentId) {
        this.userService.getPatientById(x.parentId).subscribe(p => {
          x.email = p.email;
          x.phoneNumber = p.phoneNumber;
        });
      }
    });
  }

  edit() {
    const dialogRef = this.dialog.open(CreatePatientComponent, {
      width: '320px',
      data: { edit: true, data:this.patient }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.patient = result;
      }
    });
  }

}
