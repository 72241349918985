import { Component, OnDestroy, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSnackBar } from '@angular/material/snack-bar';

import { PrescriptionService } from '../../service/prescription.service';
import { Prescription } from '../../shared/models/prescription.model';
import { GlobalConstants } from 'src/app/shared/global-constants';

import { PrescriptionStatuses, PrescriptionType, GenderPipe } from '../../shared/pipes/prescription-status.pipe'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-prescription-list',
  templateUrl: './prescription-list.component.html',
  styleUrls: ['./prescription-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class PrescriptionListComponent implements OnInit, OnDestroy {

  dataSource = [];
  columnsToDisplay = ['displayID', 'patientFullName',
    'status', 'prescriptionType', 'prefferdDeliveryDate', 'uploadDateAndTime', 'noteFromPatient'];

  prescriptions: Array<Prescription>;
  prescriptionStatusEnum = PrescriptionStatuses;
  PrescriptionTypeEnum = PrescriptionType;
  prescriptionCount: number = 0;

  interval: any;


  today: Date = new Date();
  searchQuery = "";
  listPrescriptionStatus = [];
  selectedStatus = [];
  selectedDate = "";
  listPrescriptionTypes = [0, 1, 2,3,4];
  seletedPrescptionTypes = [];
  pageIndex = 0;
  pageSize = 20;
  psType = "prescription";
  constructor(private prescriptionService: PrescriptionService, private fb: FormBuilder,
    private _snackBar: MatSnackBar, private datePipe: DatePipe) {
    console.log(`Global Pharmacy ID ` + GlobalConstants.selectedPharmacyID);
    this.finalSearchQuery += `&deliveryDate=${this.datePipe.transform(new Date(this.today.getTime() + (this.today.getTimezoneOffset() * 60 * 1000)), "yyyy-MM-dd'T'HH:mm:ss.SSS")}&pageIndex=${this.pageIndex}&pageSize=${this.pageSize}`;

    if (localStorage.getItem("psType")) {
      this.psType = localStorage.getItem("psType");
    }
    this.iniStatusFilters();


    this.refreshData();
    // this.interval = setInterval(() => {
    //   this.refreshData();
    // }, 15000);
  }
  isSearching = false;
  // SearchBy() {
  //   this.isSearching = true;
  //   $('.cstabAct').removeClass('cstabAct');
  //   setTimeout(() => {

  //     $("#isSearching").addClass("cstabAct");
  //     this.today = null;
  //     this.refreshData();
  //   }, 10);
  // }
  listPageIndex = [];
  refreshData() {

    $('.csLoader').css({ display: 'flex' });

    this.prescriptionService.getByPharmacyID(GlobalConstants.selectedPharmacyID, this.finalSearchQuery, this.psType === 'archived').subscribe(data => {
      this.listPageIndex = []
      $('.csLoader').css({ display: 'none' });
      console.log(data);
      if (data.status && data.status == "OK") {
        this.prescriptions = data.data;
        this.dataSource = this.prescriptions;
        this.prescriptionCount = data.count;
        for (var i = 0; i < data.count / this.pageSize; i++) {
          this.listPageIndex.push(i);
        }
      }
    }, error => {
      $('.csLoader').css({ display: 'none' });
    });
  }

  ngOnInit() {

    this.lastSelecteDay = 0;
    this.lastSelecteElement = $(".cstabAct")[0];
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
  finalSearchQuery = "";
  lastSelecteElement = null;
  lastSelecteDay = null;
  SelectTab(target: any, day: number) {
    this.pageIndex = 0;
    this.isSearching = false;
    this.searchQuery = "";
    var dt = new Date();
    if (day == -2) {
      this.today = null;
      this.finalSearchQuery = "";
    }
    else {
      dt.setDate(dt.getDate() + day);
      dt.setHours(0);
      dt.setMinutes(0);
      dt.setSeconds(0);
      dt.setMilliseconds(0);
      this.today = dt;
      this.finalSearchQuery = "&deliveryDate=" + this.datePipe.transform(new Date(this.today.getTime() + (this.today.getTimezoneOffset() * 60 * 1000)), "yyyy-MM-dd'T'HH:mm:ss.SSS");
    }

    this.finalSearchQuery += `&pageIndex=${this.pageIndex}&pageSize=${this.pageSize}`;
    $('.cstabAct').removeClass('cstabAct');
    $(target).addClass('cstabAct');
    this.refreshData();
    this.lastSelecteDay = day
    this.lastSelecteElement = target;
  }
  showHideSearch() {
    if (!$(".csdialog").hasClass('show')) {

      this.today = null;
    }
    $(".csdialog").toggleClass('show');
  }
  searchAndFiller() {
    this.finalSearchQuery = "";
    if (this.searchQuery) {
      this.finalSearchQuery += `&query=${this.searchQuery}`;
    }
    if (this.today) {
      this.finalSearchQuery += `&deliveryDate=${this.datePipe.transform(new Date(this.today.getTime() + (this.today.getTimezoneOffset() * 60 * 1000)), "yyyy-MM-dd'T'HH:mm:ss.SSS")}`;
    }
    if (this.selectedStatus && this.selectedStatus.length > 0) {
      this.finalSearchQuery += `&prescriptionStatus=${this.selectedStatus.join(',')}`;
    }
    if (this.seletedPrescptionTypes && this.seletedPrescptionTypes.length > 0) {
      this.finalSearchQuery += `&prescriptionType=${this.seletedPrescptionTypes.join(',')}`;
    }
    if (this.finalSearchQuery == "") {
      return;
    }
    this.pageIndex = 0;
    this.finalSearchQuery += `&pageIndex=${this.pageIndex}&pageSize=${this.pageSize}`;
    console.log(this.finalSearchQuery);
    this.showHideSearch();

    this.isSearching = true;
    $('.cstabAct').removeClass('cstabAct');
    setTimeout(() => {

      $("#isSearching").addClass("cstabAct");
      this.refreshData();
    }, 10);
  }
  gotoPage(pageInd: number) {
    var pg = this.pageIndex + pageInd;
    this.finalSearchQuery = this.finalSearchQuery.replace(`&pageIndex=${this.pageIndex}`, `&pageIndex=${pg}`);

    this.pageIndex = pg;
    this.refreshData();

  }
  psTypeChange() {
    setTimeout(() => {

      this.iniStatusFilters();

      localStorage.setItem("psType", this.psType);
      this.SelectTab(this.lastSelecteElement, this.lastSelecteDay);
    }, 300);
  }
  iniStatusFilters() {

    if (this.psType == "prescription") {

      this.listPrescriptionStatus = [0, 1, 2, 3, 4, 5, 6, 7, 10, 11];

    }

    else {

      this.listPrescriptionStatus = [8, 9];

    }

  }
}
