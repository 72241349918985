import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { PrescriptionStatuses } from 'src/app/shared/pipes/prescription-status.pipe';
import { GlobalConstants } from '../shared/global-constants';
import { Prescription } from '../shared/models/prescription.model';

@Injectable({
  providedIn: 'root'
})
export class PrescriptionService {


  public PRESCRIPTION_API = environment.baseUrl + '/prescription';
  public ORDER_API = environment.baseUrl + '/orders';
  constructor(private http: HttpClient) { }
  
  getOrder(orderId: string): Observable<any> {
    return this.http.get(`${this.ORDER_API}/${orderId}`);
  }

  getAllRc(): Observable<any> {
    return this.http.get(this.PRESCRIPTION_API +"/recurring");
  }

  getAll(): Observable<any> {
    return this.http.get(this.PRESCRIPTION_API);
  }

  get(id: string, callArchived: boolean = false) {
    return this.http.get(this.PRESCRIPTION_API + (callArchived?'/archived':'') + '/' + id);
  }

  getByPharmacyID(id: string, searchQuery: string = null,callArchived:boolean = false): Observable<any> {

    var url = this.PRESCRIPTION_API + '?pharmacyID=' + id;
    if (callArchived) { 
      url = this.PRESCRIPTION_API +"/archived" + '?pharmacyID=' + id;
    }
    if (searchQuery) {
      url += searchQuery;
    }
    return this.http.get(url);
  }

  getLastTenByPharmacy(pharmacyID): Observable<any> {
    return this.http.get(`${this.PRESCRIPTION_API}?pharmacyID=${pharmacyID}&pageIndex=0&pageSize=10`);
  }

  save(prescription: any): Observable<any> {
    let result: Observable<any>;
    if (prescription.id) {
      result = this.http.put(this.PRESCRIPTION_API, prescription);
    } else {
      result = this.http.post(this.PRESCRIPTION_API, prescription);
    }
    return result;
  }
  saveWithoutFile(prescription: any): Observable<Prescription> {
    let result: Observable<any>;
    if (prescription.id) {
      result = this.http.put(this.PRESCRIPTION_API, prescription);
    } else {
      result = this.http.post(environment.baseUrl + "/prescriptionwofile", prescription);
    }
    return result;
  }

  public upload(formData) {
    return this.http.post<any>(this.PRESCRIPTION_API, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  remove(id: string) {
    return this.http.delete(this.PRESCRIPTION_API + '/' + id);
  }

  cancel(prescriptionID: string): Observable<any> {
    let result: Observable<any>;

    var prescriptionUpdateDTO = {
      id: prescriptionID,
      status: PrescriptionStatuses.CANCELLED_BY_PHARMCY,
      noteFromPatient: "",
      noteFromPharmacy: "",
      cost: -1
    }

    result = this.http.patch<any>(this.PRESCRIPTION_API, prescriptionUpdateDTO);


    return result;
  }

  accept(prescriptionID: string): Observable<any> {
    let result: Observable<any>;

    var prescriptionUpdateDTO = {
      id: prescriptionID,
      status: PrescriptionStatuses.ACCEPTED_BY_PHARMACY,
      noteFromPatient: "",
      noteFromPharmacy: "",
      cost: -1
    }

    result = this.http.patch<any>(this.PRESCRIPTION_API, prescriptionUpdateDTO);


    return result;
  }

  readyToPickUp(prescriptionID: string): Observable<any> {
    let result: Observable<any>;

    var prescriptionUpdateDTO = {
      id: prescriptionID,
      status: PrescriptionStatuses.READY_TO_PICKUP,
      noteFromPatient: "",
      noteFromPharmacy: "",
      cost: -1
    }

    result = this.http.patch<any>(this.PRESCRIPTION_API, prescriptionUpdateDTO);


    return result;
  }
  pickedUp(prescriptionID: string): Observable<any> {
    let result: Observable<any>;

    var prescriptionUpdateDTO = {
      id: prescriptionID,
      status: PrescriptionStatuses.PICKED_UP,
      cost: -1
    }

    result = this.http.patch<any>(this.PRESCRIPTION_API, prescriptionUpdateDTO);


    return result;
  }

  readyToDeliver(prescriptionID: string, trackWithOtp: boolean, cashCollectionIsRequired: boolean,cost:number): Observable<any> {
    let result: Observable<any>;

    var prescriptionUpdateDTO = {
      id: prescriptionID,
      status: PrescriptionStatuses.READY_TO_DELIVER,
      noteFromPatient: "",
      noteFromPharmacy: "",
      cost: cost,
      trackWithOtp: trackWithOtp,
      requiredCashCollection: cashCollectionIsRequired == true ? 1 : 0
    }
    result = this.http.patch<any>(this.PRESCRIPTION_API, prescriptionUpdateDTO);

    return result;
  }
  outForDelivery(prescriptionID: string): Observable<any> {
    let result: Observable<any>;

    var prescriptionUpdateDTO = {
      id: prescriptionID,
      status: PrescriptionStatuses.OUT_FOR_DELIVERY,
      noteFromPatient: "",
      noteFromPharmacy: "",
      cost: -1
    }

    result = this.http.patch<any>(this.PRESCRIPTION_API, prescriptionUpdateDTO);


    return result;
  }

  delivered(prescriptionID: string, customerDeliveryCode: string, noOtpReason: string, cashCollected: boolean, files: any, deliveredTo: String): Observable<any> {
    let result: Observable<any>;
    var user = GlobalConstants.getcurrentUser();
    var prescriptionUpdateDTO = {
      id: prescriptionID,
      status: PrescriptionStatuses.DELIVERED,
      deliveryCode: customerDeliveryCode,
      noOtpReason: noOtpReason,
      deliveryHandler: user.firstName + " " + user.lastName,
      cashCollected: cashCollected == true ? 1 : 0,
      deliveryHandlerUserId: user.id,
      deliveredTo: deliveredTo
    }

    if (files && files.length) {
      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i])
      }
      formData.append('prescription', JSON.stringify(prescriptionUpdateDTO));
      result = this.http.patch<any>(environment.baseUrl + "/prescriptionWithImage", formData);
    }
    else {

      result = this.http.patch<any>(this.PRESCRIPTION_API, prescriptionUpdateDTO);

    }

    return result;
  }


  deliveryFailed(prescriptionID: string, failureReason: string = "", files: any = null): Observable<any> {
    let result: Observable<any>;
    let user = GlobalConstants.getcurrentUser();
    var prescriptionUpdateDTO = {
      id: prescriptionID,
      status: PrescriptionStatuses.DELIVERY_FAILED,
      failureReason: failureReason,
      deliveryHandler: user.firstName + " " + user.lastName,
      deliveryHandlerUserId : user.id
    }

    if (files && files.length) {
      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i])
      }
      formData.append('prescription', JSON.stringify(prescriptionUpdateDTO));
      result = this.http.patch<any>(environment.baseUrl + "/prescriptionWithImage", formData);
    }
    else {

      result = this.http.patch<any>(this.PRESCRIPTION_API, prescriptionUpdateDTO);

    }

    return result;
  }

  pickedupFailed(prescriptionID: string): Observable<any> {
    let result: Observable<any>;

    var prescriptionUpdateDTO = {
      id: prescriptionID,
      status: PrescriptionStatuses.PICKED_UP_FAILED,
      noteFromPatient: "",
      noteFromPharmacy: "",
      cost: -1
    }

    result = this.http.patch<any>(this.PRESCRIPTION_API, prescriptionUpdateDTO);


    return result;
  }

  PickedUp(prescriptionID: string): Observable<any> {
    let result: Observable<any>;

    var prescriptionUpdateDTO = {
      id: prescriptionID,
      status: PrescriptionStatuses.PICKED_UP,
      noteFromPatient: "",
      noteFromPharmacy: "",
      cost: -1
    }

    result = this.http.patch<any>(this.PRESCRIPTION_API, prescriptionUpdateDTO);


    return result;
  }

  addNote(prescriptionID: string, data: any): Observable<any> {
    return this.http.post<any>(`${this.PRESCRIPTION_API}/${prescriptionID}/note`, data);
  }
  getAllWhichAreReadyToPickup(): Observable<any> {
    return this.http.get(`${this.PRESCRIPTION_API}?deliveryUserID=${GlobalConstants.getcurrentUser().id}`);
  }

  findAddressRoute(from: string, to: string): Promise<any> {
    const service = new google.maps.DistanceMatrixService();
    return new Promise<any>((resolve, reject) => {

      service.getDistanceMatrix(
        {
          origins: [from],
          destinations: [to],
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.METRIC,
          avoidHighways: false,
          avoidTolls: false,
        },
        (response, status) => {
          if (status !== "OK") {
            reject("Can't calculate distance");
          } else {
            resolve(response);
          }
        });
    });
  }
  findAddressRouteAddress(from: string, to: []): Promise<any> {
    const service = new google.maps.DistanceMatrixService();
    return new Promise<any>((resolve, reject) => {

      service.getDistanceMatrix(
        {
          origins: [from],
          destinations: to,
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.METRIC,
          avoidHighways: false,
          avoidTolls: false,
        },
        (response, status) => {
          if (status !== "OK") {
            reject("Can't calculate distance");
          } else {
            resolve(response);
          }
        });
    });
  }
  findAddressUsingLatLon(latlng: any): Promise<any> {

    return new Promise<any>((resolve, reject) => {
      this.service.geocode({ location: latlng }, (
        results: google.maps.GeocoderResult[],
        status: google.maps.GeocoderStatus
      ) => {
        if (status === "OK") {
          if (results[0]) {
            resolve(results);
          }
          else {
            reject('No results found');
          }
        }
        else {
          reject('No results found');
        }
      });

    }
    );
  }

  getDataForRouting(): Observable<any> {
    return this.http.get('http://api.mylocalrx.ca/prescription?pharmacyID=602a7d4335c65a23da55ec4d&deliveryDate=05/14/2021&pageIndex=0&pageSize=20');
  }
  service = new google.maps.Geocoder();
  getLatLonByAddress(addresses: any): Promise<any> {
    if (this.service == null) {
      this.service = new google.maps.Geocoder();
    }
    return new Promise<any>((res, rej) => {
      this.service.geocode({ 'address': addresses }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          console.log(results);
          res(results[0].geometry.location);
        } else {
          console.log(status);
          rej("can't find address " + addresses)
        }
      });
    });
  }
}
