import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PrescriptionComponent } from './prescription/prescription/prescription.component';
import { HomeComponent } from './home/home.component';
import { PrescriptionListComponent } from './prescription/prescription-list/prescription-list.component';
import { PrescriptionEditComponent } from './prescription/prescription-edit/prescription-edit.component';
import { PrescriptionDetailComponent } from './prescription/prescription-detail/prescription-detail.component';
import { PrescriptionStatusComponent } from './prescription/prescription-status/prescription-status.component';
import { AppointmentComponent } from './appointment/appointment/appointment.component';
import { AppointmentListComponent } from './appointment/appointment-list/appointment-list.component';
import { AppointmentEditComponent } from './appointment/appointment-edit/appointment-edit.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { UserResolver } from './shared/user.resolver';
import { AuthGuard } from './shared/AuthGuard';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { MyOrderComponent } from './my-order/my-order.component';
import { ReadyToPickupComponent } from './ready-to-pickup/ready-to-pickup.component';
import { MyPharmaciesComponent } from './ready-to-pickup/my-pharmacies/my-pharmacies.component';
import { PatientDetailComponent } from './patient-detail/patient-detail.component';
import { DeliveryRoutingComponent } from './delivery-routing/delivery-routing.component';
import { WaitingListComponent } from './appointment/waiting-list/waiting-list.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { MinorAilmentsComponent } from './minor-ailments/minor-ailments.component';
// import { MinorprescriptionhistoryComponent } from './minorprescriptionhistory/minorprescriptionhistory.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'login', component: LoginPageComponent, canActivate: [AuthGuard] },
  {
    path: 'prescription', resolve: { data: UserResolver },
    component: PrescriptionComponent,
    children: [
      {
        path: 'prescription-list',
        component: PrescriptionListComponent
      },
      {
        path: 'prescription-add',
        component: PrescriptionEditComponent
      },
      {
        path: 'prescription-edit',
        component: PrescriptionEditComponent
      },
      {
        path: 'prescription-edit/:id',
        component: PrescriptionEditComponent
      },
      {
        path: 'prescription-detail/:id/:psType',
        component: PrescriptionDetailComponent
      }

    ]
  },

  {
    path: 'prescription-status/:id',
    component: PrescriptionStatusComponent
  },
  {
    path: 'appointment', resolve: { data: UserResolver },
    component: AppointmentListComponent,
  //   children: [
  //     {
  //       path: 'appointment-list',
  //       component: AppointmentListComponent
  //     },
  //     {
  //       path: 'appointment-add',
  //       component: AppointmentEditComponent
  //     },
  //     {
  //       path: 'appointment-edit',
  //       component: AppointmentEditComponent
  //     },
  //     {
  //       path: 'appointment-edit/:id',
  //       component: AppointmentEditComponent
  //     }

  //   ]
   },
  {
    path: 'dashboard',
    resolve: { data: UserResolver },
    component: DashboardComponent,

  },
  {
    path: 'configuration',
    resolve: { data: UserResolver },
    component: ConfigurationComponent
  }, {
    path: 'manage-users',
    resolve: { data: UserResolver },
    component: ManageUsersComponent
  },{
    path: 'my-order',
    resolve: { data: UserResolver },
    component: MyOrderComponent
  },
  
  {
    path: 'minor-ailments',
    resolve: { data: UserResolver },
    component: MinorAilmentsComponent
  },
  // {
  //   path: 'minorprescriptionhistory',
  //   resolve: { data: UserResolver },
  //   component: MinorprescriptionhistoryComponent
  // },
  {
    path: 'ready-to-pickup',
    resolve: { data: UserResolver },
    component: ReadyToPickupComponent
  }, {
    path: 'my-pharmacies',
    resolve: { data: UserResolver },
    component: MyPharmaciesComponent
  },
  {
    path: 'patient/detail/:id',
    resolve: { data: UserResolver },
    component: PatientDetailComponent,

  }, {
    path: 'delivery-routing',
    resolve: { data: UserResolver },
    component: DeliveryRoutingComponent,

  }, {
    path: 'waiting-list',
    resolve: { data: UserResolver },
    component: WaitingListComponent,

  },{
    path: 'home',
    component: HomeComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
