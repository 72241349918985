import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GlobalConstants } from './global-constants';
declare var $: any;
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.method.toLocaleLowerCase() == "put" || request.method.toLocaleLowerCase() == "post" || request.method.toLocaleLowerCase() == "patch") {
            $('.csLoader').css({ display: 'flex' });
            return next.handle(this.addCustomHeaders(request)).pipe(
                catchError(this.handleError),
                map((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        $('.csLoader').css({ display: 'none' });
                    }
                    return event;
                }));
        }
        else {
            return next.handle(this.addCustomHeaders(request)).pipe(
                catchError(this.handleError),
                map((event: HttpEvent<any>) => {

                    return event;
                }));
        }
    }

    handleError(error: HttpErrorResponse) {
        $('.csLoader').css({ display: 'none' });
        console.log(error);
        // GlobalConstants.clearCurrentUserSession();
        if (error.status == 401) {
            setTimeout(() => {
                ///window.location.href = window.location.origin;
                $("#openLogin").trigger("click");
            }, 2000);
        }
        return throwError(error);
    }
    addCustomHeaders(request: HttpRequest<any>) {
        var currentUser = GlobalConstants.getcurrentUser();
        var token = currentUser ? currentUser.token : "";
        var sessionId = GlobalConstants.getCurrentSessionId();
        if (request.url.indexOf("token") > -1 || request.url.indexOf("vehicle") > -1||request.url.indexOf("siteconfig") > -1) {
             token = "";
        }
        if (token) {
            return request.clone({
                setHeaders: {
                    session_id: sessionId,
                    Authorization: "Bearer " + token
                }
            });
        }
        else {
            return request.clone({
                setHeaders: {
                    session_id: sessionId
                }
            });
        }
    }
}